import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
const Performance = () => {
    const navigate = useNavigate();
    const data = [
        {
            date: '01/03/2025',
            s1CE: 0,
            s1CE2nd: 0,
            s1PE: 0,
            s1PE2nd: 0,
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'Saturday',
        },
        {
            date: '02/03/2025',
            s1CE: 0,
            s1CE2nd: 0,
            s1PE: 0,
            s1PE2nd: 0,
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'Sunday',
        },
        {
            date: '03/03/2025',
            s1CE: 0,
            s1CE2nd: 0,
            s1PE: 0,
            s1PE2nd: 0,
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'No Trade',
        },
        {
            date: '04/03/2025',
            s1CE: -690,
            s1CE2nd: 2910,
            s1PE: 0,
            s1PE2nd: 0,
            netPL: 2220,
            percentPL: '4.44%',
            remarks: '',
        },
        {
            date: '05/03/2025',
            s1CE: 0,
            s1CE2nd: 0,
            s1PE: 0,
            s1PE2nd: 0,
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'No Trade',
        },
        {
            date: '06/03/2025',
            s1CE: 0,
            s1CE2nd: 0,
            s1PE: 0,
            s1PE2nd: 0,
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'No Trade',
        },
        {
            date: '07/03/2025',
            s1CE: 3690,
            s1CE2nd: 3168,
            s1PE: 0,
            s1PE2nd: 0,
            netPL: 6858,
            percentPL: '13.72%',
            remarks: '',
        },
        {
            date: '08/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'Saturday',
        },
        {
            date: '09/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'Sunday',
        },
        {
            date: '10/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: 7000,
            s1PE2nd: '',
            netPL: 7000,
            percentPL: '14.00%',
            remarks: '',
        },
        {
            date: '11/03/2025',
            s1CE: -60,
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: -60,
            percentPL: '-0.12%',
            remarks: '',
        },
        {
            date: '12/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'No Trade',
        },
        {
            date: '13/03/2025',
            s1CE: -2580,
            s1CE2nd: 800,
            s1PE: '',
            s1PE2nd: '',
            netPL: -1780,
            percentPL: '-3.56%',
            remarks: '',
        },
        {
            date: '14/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'HOLI',
        },
        {
            date: '15/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'Saturday',
        },
        {
            date: '16/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'Sunday',
        },
        {
            date: '17/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'No Trade',
        },
        {
            date: '18/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: 'No Trade',
        },
        {
            date: '19/03/2025',
            s1CE: -3225,
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: -3225,
            percentPL: '-6.45%',
            remarks: '',
        },
        {
            date: '20/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '21/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '22/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '23/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '24/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '25/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '26/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '27/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '28/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '29/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '30/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
        {
            date: '31/03/2025',
            s1CE: '',
            s1CE2nd: '',
            s1PE: '',
            s1PE2nd: '',
            netPL: 0,
            percentPL: '0.00%',
            remarks: '',
        },
    ];

    const totalInvested = 50000;

    const overallPL = data.reduce((sum, row) => {
        const netPL = typeof row.netPL === 'number' ? row.netPL : 0;  // Handle non-numeric netPL values

        return sum + netPL;
    }, 0);

    return (
        <Paper>
            <Box
                sx={{
                    height: '50px',
                    backgroundColor: 'rgb(30, 136, 229)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start', // Aligns the text to the left
                    paddingLeft: '16px', // Adds some padding to the left for spacing
                    borderRadius: '8px', // Optional: curved edges
                    color: 'white', // Text color
                    mb: 5
                }}
            >
                <Typography variant="h6">
                    Performance
                </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-start">
                <Button
                    variant="contained"
                    onClick={() => navigate(-1)}
                    startIcon={<Iconify icon="eva:arrow-left-fill" />}
                >
                    Back
                </Button>
            </Box>
            <Typography variant="h6" sx={{ mt: 2, ml: 2 }}>
                Total Invested: {totalInvested} INR
            </Typography>
            <Typography variant="h6" sx={{ mt: 1, ml: 2 }}>
                Overall P&L: {overallPL} INR
            </Typography>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell align="right">S1 CE</TableCell>
                            <TableCell align="right">S1 CE (2nd)</TableCell>
                            <TableCell align="right">S1 PE</TableCell>
                            <TableCell align="right">S1 PE (2nd)</TableCell>
                            <TableCell align="right">Net P & L</TableCell>
                            <TableCell align="right">% P & L</TableCell>
                            <TableCell align="right">Remarks</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow
                                key={row.date}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.date}
                                </TableCell>
                                <TableCell align="right">{row.s1CE}</TableCell>
                                <TableCell align="right">{row.s1CE2nd}</TableCell>
                                <TableCell align="right">{row.s1PE}</TableCell>
                                <TableCell align="right">{row.s1PE2nd}</TableCell>
                                <TableCell align="right">{row.netPL}</TableCell>
                                <TableCell align="right">{row.percentPL}</TableCell>
                                <TableCell align="right">{row.remarks}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default Performance;