import React from 'react';
import {
    Grid,
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
    MenuItem,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    addDoc,
    collection,
    doc,
    serverTimestamp,
    getDoc,
    updateDoc,
} from "firebase/firestore";
import { useAuth } from "../../../context/AuthContext";
import { db } from "../../../firebase";  // Import only db, auth and storage are not used

// Constants for options
const ALL_EXCHANGES = [  // All exchange options
    { value: 'Binance', label: 'Binance' },
    { value: 'Bybit', label: 'Bybit' },
    { value: 'Angelone', label: 'Angelone' },
];

const DEFAULT_TRADE_CATEGORIES = [
    { value: 'Options', label: 'Options' },
    { value: 'CryptoFutures', label: 'Crypto Futures' },
];

const DEFAULT_MARKET_TYPES = [{ value: 'NIFTY50', label: 'NIFTY50' }];

const DEFAULT_TRANSACTION_OPTIONS = ['Buy', 'Sell'];
const DEFAULT_OPTION_TYPES = [{ value: 'CE', label: 'CE' }, { value: 'PE', label: 'PE' },{ value: 'Both', label: 'Both' }];

//Dynamic OptionTypes/MarketTypes/Exchanges for CryptoFutures
const CRYPTO_OPTION_TYPES = [{ value: 'Long', label: 'Long' }, { value: 'Short', label: 'Short' },{ value: 'Both', label: 'Both' }];
const CRYPTO_MARKET_TYPES = [{ value: 'FUTURES', label: 'FUTURES' }];
const CRYPTO_EXCHANGES = ALL_EXCHANGES.filter(exchange => exchange.value === 'Binance' || exchange.value === 'Bybit');

// Validation schema using Yup
const validationSchema = Yup.object().shape({
    statName: Yup.string().required('Strategy Name is Required'),
    tradeCategory: Yup.string().required('Trade Category is Required'),
    transaction: Yup.string().required('Transaction Type is Required'),
    optionType: Yup.string().required('Option Type is Required'),
    market: Yup.string().required('Market is Required'),
    exchange: Yup.string().required('Exchange is Required'),
    rules: Yup.string().required('Rules are Required'),
});

const CreateAlgoForm = () => {
    const { currentUser } = useAuth();  // Only currentUser needed
    const initialValues = {
        statName: '',
        tradeCategory: '',
        transaction: '',
        optionType: '',
        market: '',
        exchange: '',
        rules: '',
    };

    const handleSubmit = async (values, { resetForm, setSubmitting }) => {
        setSubmitting(true);  // Disable the submit button

        try {
            const customerDocRef = doc(db, 'Customers', currentUser.uid);
            const customerDocSnap = await getDoc(customerDocRef);

            if (!customerDocSnap.exists()) {
                alert('No customer data found. Please contact support.');
                return;
            }

            // 1. Create the Strategy document
            const strategiesCollectionRef = collection(db, 'Strategies');
            const rulesArray = values.rules.split(',').map(rule => rule.trim()); //Split the rules, trim whitespace
            const newStrategy = {
                statName: values.statName,
                tradeCategory: values.tradeCategory,
                transaction: values.transaction,
                optionType: values.optionType,
                market: values.market,
                exchange: values.exchange,
                rules: rulesArray, // store rules as array
                logs: [],
                tradeEngines: [],
                createdBy: currentUser.uid,
                createdTimeStamp: serverTimestamp(),
                lastChangedBy: '',
                lastChangedTimeStamp: serverTimestamp()
            };

            const strategyDocRef = await addDoc(strategiesCollectionRef, newStrategy);

            // 2. Update the Customer document to include the new Strategy
            const customerData = customerDocSnap.data();
            const existingStrategies = customerData.strategies || []; //safely get existing strategies, in case it is not set up yet
            const updatedStrategies = [...existingStrategies, {
                name: values.statName,
                id: strategyDocRef.id
            }];

            await updateDoc(customerDocRef, { strategies: updatedStrategies });

            // 3. Reset form and notify user
            alert(`Strategy created successfully with ID: ${strategyDocRef.id}`);
            resetForm();

        } catch (error) {
            console.error("Error creating strategy: ", error);
            alert("Failed to create strategy. Please contact support.");
        } finally {
            setSubmitting(false);  // Re-enable the submit button
        }
    };

    return (
        <div className="App">
            <Grid>
                <Card style={{ maxWidth: 750, padding: '20px 5px', margin: '0 auto' }}>
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            Strategy Build Info
                        </Typography>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, errors, touched, values, handleChange, setFieldValue }) => {
                                //Dynamically populate the optiontypes,markettypes and exchanges based on tradetype selection
                                const optionTypes = values.tradeCategory === 'CryptoFutures' ? CRYPTO_OPTION_TYPES : DEFAULT_OPTION_TYPES;
                                const marketTypes = values.tradeCategory === 'CryptoFutures' ? CRYPTO_MARKET_TYPES : DEFAULT_MARKET_TYPES;
                                const exchanges = values.tradeCategory === 'CryptoFutures' ? CRYPTO_EXCHANGES : ALL_EXCHANGES;

                                //Clear values when tradecategory changes
                                React.useEffect(() => {
                                    if (values.tradeCategory === 'CryptoFutures') {
                                        setFieldValue('optionType', '');
                                        setFieldValue('market', '');
                                        setFieldValue('exchange', '');
                                    }
                                }, [values.tradeCategory, setFieldValue]);

                                return (
                                    <Form>
                                        <Grid container spacing={2}>  {/* Added spacing for better layout */}
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    name="statName"
                                                    label="Strategy Name"
                                                    fullWidth
                                                    error={errors.statName && touched.statName}
                                                    helperText={<ErrorMessage name="statName" />}
                                                    required
                                                    variant="outlined"
                                                    value={values.statName}
                                                    onChange={handleChange}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth variant="outlined" error={errors.tradeCategory && touched.tradeCategory}>
                                                    <TextField
                                                        select
                                                        label="Trade Category"
                                                        name="tradeCategory"
                                                        value={values.tradeCategory}  //Use initialValues for value
                                                        onChange={handleChange}  // Add onChange handler
                                                        helperText={<ErrorMessage name="tradeCategory" />}
                                                        required
                                                    >
                                                        {DEFAULT_TRADE_CATEGORIES.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl component="fieldset" error={errors.transaction && touched.transaction}>
                                                    <Typography variant="subtitle1" color="textSecondary">
                                                        Transaction:
                                                    </Typography>
                                                    <RadioGroup row aria-label="transaction" name="transaction" value={values.transaction} onChange={handleChange}>
                                                        {DEFAULT_TRANSACTION_OPTIONS.map((option) => (
                                                            <FormControlLabel
                                                                key={option}
                                                                value={option}
                                                                control={<Radio />}
                                                                label={option}
                                                                disabled={option === 'Sell'} // Disable "Sell" option
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                    <ErrorMessage name="transaction" component="div" className="error" />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl component="fieldset" error={errors.optionType && touched.optionType}>
                                                    <Typography variant="subtitle1" color="textSecondary">
                                                        Option Type:
                                                    </Typography>
                                                    <RadioGroup row aria-label="optionType" name="optionType" value={values.optionType} onChange={handleChange}>
                                                        {optionTypes.map((option) => (
                                                            <FormControlLabel
                                                                key={option.value}
                                                                value={option.value}
                                                                control={<Radio />}
                                                                label={option.label}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                    <ErrorMessage name="optionType" component="div" className="error" />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth variant="outlined" error={errors.market && touched.market}>
                                                    <TextField
                                                        select
                                                        label="Market"
                                                        name="market"
                                                        value={values.market}  //Use initialValues for value
                                                        onChange={handleChange}  // Add onChange handler
                                                        helperText={<ErrorMessage name="market" />}
                                                        required
                                                    >
                                                        {marketTypes.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <FormControl fullWidth variant="outlined" error={errors.exchange && touched.exchange}>
                                                    <TextField
                                                        select
                                                        label="Exchange"
                                                        name="exchange"
                                                        value={values.exchange}   //Use initialValues for value
                                                        onChange={handleChange}  // Add onChange handler
                                                        helperText={<ErrorMessage name="exchange" />}
                                                        required
                                                    >
                                                        {exchanges.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <TextField
                                                    name="rules"
                                                    label="Rules (separated by comma)"
                                                    fullWidth
                                                    multiline
                                                    rows={3}
                                                    error={errors.rules && touched.rules}
                                                    helperText={<ErrorMessage name="rules" />}
                                                    required
                                                    variant="outlined"
                                                    value={values.rules}
                                                    onChange={handleChange}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={isSubmitting}  //Disable button while submitting
                                                >
                                                    {isSubmitting ? 'Submitting...' : 'Submit'}  {/* Change text during submission */}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};

export default CreateAlgoForm;