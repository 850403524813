import { useState, useEffect, useCallback } from 'react'; // useCallback added
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard'; // Consider if this is truly needed

import {
    addDoc,
    collection,
    doc,
    serverTimestamp,
    setDoc,  // Consider if needed
    query,
    where,
    getDocs,
    updateDoc,
    getDoc,
    deleteDoc,
    documentId
} from "firebase/firestore";

import { getDatabase, ref, set, onValue, update, child, push } from "firebase/database";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar, //Consider if needed
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Box,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Collapse,
    Grid,
    Select,
    InputLabel,
    List,
    ListItem,
    ListItemText
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
// Dialog
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';  // Consider if needed
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText'; // Consider if needed
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
// components
import Label from '../components/label'; //Consider if needed
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

import InfoIcon from '@mui/icons-material/Info'; // Consider if needed
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LightbulbIcon from '@mui/icons-material/Lightbulb'; // Consider if needed
// sections
import { UserListHead, UserListToolbar, AddExchangeForm } from '../sections/@dashboard/user';
// mock
// import USERLIST from '../_mock/user';  // Remove if not used
import { auth, db, rdb, storage } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { GifBoxSharp, Margin } from '@mui/icons-material'; // Consider if needed
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Strategy', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'market', label: 'Market', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function MyAlgosPage() {
    // States
    const [algos, setAlgos] = useState([]);
    const [uAlgos, setUAlgos] = useState([]);
    const [open, setOpen] = useState(null);
    const [statId, setStatId] = useState(null);
    const [objectPage, setObjectPage] = useState(false);
    const [selectedAlgo, setSelectedAlgo] = useState(null);
    const [edit, setEdit] = useState(false);
    const [copied, setCopied] = useState(false);  // Consider if this is truly needed
    const [algoActive, setAlgoActive] = useState(null); // Consider if this is truly needed
    const [isAdmin, setIsAdmin] = useState(false);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openDialog, setOpenDialog] = useState(false);
    const [exchangeList, setExchangeList] = useState([]);
    const [loading, setLoading] = useState(false);

    // Form State
    const [algoName, setAlgoName] = useState('');
    const [algoType, setAlgoType] = useState('');
    const [market, setMarket] = useState('');
    const [additional, setAdditional] = useState('');
    const [rules, setRules] = useState('');

    const { currentUser } = useAuth();


    // Moved inside the component
    const USERLIST = exchangeList;

    // Data Fetching
    const getList = useCallback(async () => {
        setLoading(true); // Start loading before the request
        try {
            const docRef = doc(db, 'Customers', currentUser.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const customerData = docSnap.data();
                const aStrategies = customerData.strategies || []; // Ensure strategies exist
                const aStrategiesIds = aStrategies.map((strategy) => strategy.id);
                setUAlgos(aStrategies);
                setIsAdmin(!!customerData.isAdmin); // Boolean conversion

                if (aStrategiesIds.length > 0) { // Check if there are strategies
                    const q = query(collection(db, 'Strategies'), where(documentId(), 'in', aStrategiesIds));
                    const querySnapshot = await getDocs(q);
                    const list = querySnapshot.docs.map((doc) => {
                        const strategy = doc.data();
                        return {
                            name: strategy.statName,
                            type: strategy.tradeCategory,
                            market: strategy.market,
                            id: doc.id,
                            // ...strategy, // Spread the rest of the strategy data (optional)
                        };
                    });
                    setAlgos(list);
                    setExchangeList(list);

                } else {
                    // Handle the case where there are no strategies
                    setAlgos([]);
                    setExchangeList([]);
                }

            } else {
                console.log("No such document!");
                setAlgos([]);
                setExchangeList([]);
            }
        } catch (error) {
            console.error("Error fetching data: ", error);
            //Consider adding more user friendly error messages in UI
        } finally {
            setLoading(false); // Ensure loading is always set to false
        }
    }, [currentUser.uid, db]);  // Dependencies for useCallback


    useEffect(() => {
        getList();
    }, [getList]);

    // Dialog Handlers
    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    // Menu Handlers
    const handleOpenMenu = (event, id) => {
        setOpen(event.currentTarget);
        setStatId(id);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };


    const handleShowMoreDetails = useCallback(async (event, id) => {
        setObjectPage(true);
        setStatId(id);

        try {
            const docRef = doc(db, 'Strategies', id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const strategy = docSnap.data();

                // Process logs based on market type
                let logs = [];
                if (strategy.market === "CRYPTO") {
                    logs = processCryptoLogs(strategy.logs);
                } else if (strategy.market === "NIFTY50") {
                    logs = await processNifty50Logs(strategy); // Correctly call the new async function
                }

                // Update the selectedAlgo state
                setSelectedAlgo({ ...strategy, logs });
                setAlgoName(strategy.statName);
                setAlgoType(strategy.tradeCategory);
                setMarket(strategy.market);
                setAdditional(strategy.additionalCoins);
                setRules(strategy.rules);
            } else {
                console.log("No such strategy document!");
                setSelectedAlgo(null);
                // Consider showing a user-friendly error message
            }
        } catch (error) {
            console.error("Error fetching strategy details: ", error);
            setSelectedAlgo(null);
            // Consider showing a user-friendly error message
        }
    }, [db]);  // Add dependencies for useCallback


    const processCryptoLogs = (cryptoLogs) => {
        if (!cryptoLogs || !Array.isArray(cryptoLogs)) {
            return [];
        }

        return cryptoLogs.map(log => {
            const s1 = log.S1?.support || "";
            const s2 = log.S2?.support || "";
            const s3 = log.S3?.support || "";
            const s4 = log.S4?.support || "";
            return `symbol- ${log.symbol} buy points - ${s1},${s2},${s3},${s4}`;
        });
    };

    // Modified processNifty50Logs to be async
    const processNifty50Logs = async (strategy) => {
        if (!strategy.logAon || !Array.isArray(strategy.logAon)) {
            return [];
        }

        return strategy.logAon.map(logEntry => {
            const tradeEngines = logEntry.TE.map(te => te.tradeEngineName).join(', ');
            const tradeHappened = logEntry.TE.length > 0 ? "Yes" : "No";

            const candle0 = JSON.parse(logEntry.candle0);
            const candle1 = JSON.parse(logEntry.candle1);

            return `
        Date & Time: ${logEntry.logCollectedDate.toDate()}
        Candle 0 (OHLC): ${candle0[1]}, ${candle0[2]}, ${candle0[3]}, ${candle0[4]}
        Candle -1 (OHLC): ${candle1[1]}, ${candle1[2]}, ${candle1[3]}, ${candle1[4]}
        Trade Happened: ${tradeHappened}
        Trade Engines linked & order executed: ${tradeEngines}
      `;
        });
    };

    const handleShowDetails = async () => {
        setOpen(null);
        setObjectPage(true);
    };

    const TimestampDisplay = ({ timestamp }) => {
        if (!timestamp) return null;

        const date = timestamp.toDate(); // Directly convert to Date object
        const formattedDate = date.toLocaleString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
        return formattedDate;
    };

    // Table Handlers
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = algos.map((n) => n.id);  //Select by ID
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {  // Pass id instead of name
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    // Edit Handlers
    const handleEdit = async (event) => {
        setEdit(true);
    };

    const handleSave = async (event) => {
        try {
            const docRef = doc(db, 'Strategies', statId);
            await updateDoc(docRef, {
                additionalCoins: additional,
                // ... other fields you want to update
            });

            // Optimistically update the selectedAlgo state
            setSelectedAlgo(prevAlgo => ({ ...prevAlgo, additionalCoins: additional }));

            setEdit(false);
            alert("Algo updated successfully!");

        } catch (error) {
            console.error('Error updating algo: ', error);
            alert("Failed to update algo. Please check the console for errors.");
        }
    };

    const handleDisable = async (event) => {
        if (!statId) return;

        try {
            const docRef = doc(db, 'strategies', statId);
            await updateDoc(docRef, { active: false });
            setAlgoActive(false);
            alert("Strategy deactivated successfully!");

        } catch (error) {
            console.error('Error deactivating strategy: ', error);
            alert("Failed to deactivate strategy. Please check the console for errors.");
        }
    };

    const handleActivate = async (event) => {
        if (!statId) return;

        try {
            const docRef = doc(db, 'strategies', statId);
            await updateDoc(docRef, { active: true });
            setAlgoActive(true);
            alert("Strategy activated successfully!");

        } catch (error) {
            console.error('Error activating strategy: ', error);
            alert("Failed to activate strategy. Please check the console for errors.");
        }
    };

    const handleManualTrigger = async (event) => {
        if (!statId) return;

        try {
            const newPostKey = push(child(ref(rdb), 'triggers')).key;
            const updates = {};
            updates[`/triggers/${newPostKey}`] = { sId: statId };

            await update(ref(rdb), updates);
            alert("Manual trigger initiated successfully! Please wait for 5 seconds and refresh.");

        } catch (error) {
            console.error('Error initiating manual trigger: ', error);
            alert("Failed to initiate manual trigger. Please check the console for errors.");
        }
    };

    const handleManualTriggerList = async (algoId) => {
        if (!algoId) return;

        try {
            const newPostKey = push(child(ref(rdb), 'triggers')).key;
            const updates = {};
            updates[`/triggers/${newPostKey}`] = { sId: algoId };

            await update(ref(rdb), updates);
            alert("Manual trigger initiated successfully! Please wait for 5 seconds and refresh.");

        } catch (error) {
            console.error('Error initiating manual trigger: ', error);
            alert("Failed to initiate manual trigger. Please check the console for errors.");
        }
    };

    const handleDeleteAlgo = async () => {
        alert("Delete not allowed");
        return;
    };

    const handleBackToList = () => {
        setObjectPage(false);
    };

    // Table Calculations
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - algos.length) : 0;
    const filteredUsers = applySortFilter(algos, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredUsers.length && !!filterName;

    return (
        <>
            <div style={{ textAlign: 'center' }}>
                {loading && <CircularProgress size={80} style={{ color: 'grey' }} />}
            </div>
            <Helmet>
                <title> Exchanges | customrapid </title>
            </Helmet>
            <Container>
                <Box
                    sx={{
                        height: '50px',
                        backgroundColor: 'rgb(30, 136, 229)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: '16px',
                        borderRadius: '8px',
                        color: 'white',
                        mb: 5
                    }}
                >
                    <Typography variant="h6">
                        My Strategies
                    </Typography>
                </Box>

                {!objectPage ? (
                    <>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
                            <Stack direction="row" spacing={2}>
                                <Button variant="contained" component={Link} to="/dashboard/addalgo" startIcon={<Iconify icon="eva:plus-fill" />}>
                                    Add
                                </Button>
                            </Stack>
                        </Stack>

                        <Card>
                            <UserListToolbar
                                numSelected={selected.length}
                                filterName={filterName}
                                onFilterName={handleFilterByName}
                                onDeleteAlgo={handleDeleteAlgo}
                            />

                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 800 }}>
                                    <Table>
                                        <UserListHead
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={algos.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}
                                        />
                                        <TableBody>
                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                const { id, name, type, market } = row;
                                                const isItemSelected = selected.indexOf(id) !== -1;

                                                return (
                                                    <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={isItemSelected}>
                                                        <TableCell padding="checkbox">
                                                            <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, id)} />
                                                        </TableCell>
                                                        <TableCell align="left">{name}</TableCell>
                                                        <TableCell align="left">{type}</TableCell>
                                                        <TableCell align="left">{market}</TableCell>
                                                        <TableCell align="right">
                                                            {isAdmin &&
                                                                <IconButton size="large" color="inherit" onClick={(event) => handleShowMoreDetails(event, id)}>
                                                                    <Iconify icon={'uiw:d-arrow-right'} />
                                                                </IconButton>
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                            {emptyRows > 0 && (
                                                <TableRow style={{ height: 53 * emptyRows }}>
                                                    <TableCell colSpan={6} />
                                                </TableRow>
                                            )}
                                        </TableBody>

                                        {isNotFound && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                        <Paper
                                                            sx={{
                                                                textAlign: 'center',
                                                            }}
                                                        >
                                                            <Typography variant="h6" paragraph>
                                                                Not found
                                                            </Typography>

                                                            <Typography variant="body2">
                                                                No results found for  
                                                                <strong>"{filterName}"</strong>.
                                                                <br /> Try checking for typos or using complete words.
                                                            </Typography>
                                                        </Paper>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Scrollbar>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={algos.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Card>
                    </>
                ) : (
                    selectedAlgo && (
                        <Card>
                            <Box display="flex" justifyContent="flex-end" p={2}>
                                <Button
                                    variant="contained"
                                    onClick={handleBackToList}
                                    startIcon={<Iconify icon="eva:arrow-left-fill" />}
                                >
                                    Back
                                </Button>
                            </Box>

                            <Collapse in={selectedAlgo !== null} timeout="auto" unmountOnExit>
                                <Paper variant="outlined" square style={{ padding: '10px', margin: '10px' }}>
                                    <Typography variant="h6" component="div">
                                        Strategy Name: {selectedAlgo.statName}
                                    </Typography>

                                    <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={2} mr={2}>
                                        <Box mr={1} mb={1}>
                                            <Button variant="contained" onClick={handleEdit} color="primary" aria-label="edit" startIcon={<EditIcon />}>
                                                Edit
                                            </Button>
                                        </Box>
                                        <Box mb={1}>
                                            <Button variant="contained" onClick={handleSave} color="primary" aria-label="save" startIcon={<SaveIcon />}>
                                                Save
                                            </Button>
                                        </Box>
                                    </Stack>

                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item xs={12} sm={12} md={4}>
                                            <Typography variant="body2" color="text.secondary">
                                                Trade Category: {selectedAlgo.tradeCategory}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <Typography variant="body2" color="text.secondary">
                                                Market: {selectedAlgo.market}
                                            </Typography>
                                        </Grid>

                                        {edit ? (
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TextField
                                                    fullWidth
                                                    label="Additional"
                                                    type="string"
                                                    variant="outlined"
                                                    value={additional}
                                                    size="small"
                                                    margin="normal"
                                                    onChange={(e) => setAdditional(e.target.value)}
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12} sm={12} md={4}>
                                                <Typography variant="body2" color="text.secondary">
                                                    Additional: {selectedAlgo.additionalCoins}
                                                </Typography>
                                            </Grid>
                                        )}

                                        <Grid item xs={12} sm={12} md={8}>
                                            <Typography variant="body2" color="text.secondary">
                                                Rules: {selectedAlgo.rules[0]}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body2" color="text.secondary">
                                                Logs:
                                            </Typography>
                                            <List>
                                                {selectedAlgo.logs && selectedAlgo.logs.map((log, index) => (
                                                    <ListItem key={index} divider>
                                                        <ListItemText
                                                            primary={`Log #${index + 1}`}
                                                            secondary={log}
                                                            sx={{ wordWrap: "break-word" }}
                                                        />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Collapse>
                        </Card>
                    )
                )}
            </Container>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleShowDetails}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>

            <Dialog aria-labelledby="customized-dialog-title" open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle id="customized-dialog-title">
                    Add Exchange
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <AddExchangeForm />
                </DialogContent>
            </Dialog>
        </>
    );
}