/* eslint-disable */
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";



//Test
const app = initializeApp({
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGEING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    databaseURL: "https://custom-rapid-production-default-rtdb.firebaseio.com",
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID

});

const app2 = initializeApp({
    apiKey: process.env.REACT_APP_FB_API_KEY2,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN2,
    projectId: process.env.REACT_APP_FB_PROJECT_ID2,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET2,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGEING_SENDER_ID2,
    appId: process.env.REACT_APP_FB_APP_ID2,
    databaseURL: "https://auth-development-4e138-default-rtdb.firebaseio.com",
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID2

}, "project2");


export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
// export const rdb = getDatabase(app);

export const rdb = getDatabase(app2);

export const auth = getAuth();
export default app
