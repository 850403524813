import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, CardActionArea, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  transition: 'transform 0.2s',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[5], // Enhanced shadow on hover
  },
}));

const StyledCardActionArea = styled(CardActionArea)({
  height: '100%', // Ensure the entire card is clickable
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px', // More padding for better visual appeal
});

const MyTradeEngines = () => {
  const navigate = useNavigate();

  const handleTileClick = (path) => {
    navigate(path);
  };

  return (

    <Grid container spacing={3} sx={{ padding: 3 }}>

      <Grid item xs={12}>
        <Box
          sx={{
            height: '50px',
            backgroundColor: 'rgb(30, 136, 229)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns the text to the left
            paddingLeft: '16px', // Adds some padding to the left for spacing
            borderRadius: '8px', // Optional: curved edges
            color: 'white', // Text color
            mb: 5
          }}
        >
          <Typography variant="h6">
            My TradeEngines
          </Typography>
        </Box>

      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <StyledCard>
          <StyledCardActionArea onClick={() => handleTileClick('/dashboard/myenginesmanual')}>
            <CardContent>
              <Typography variant="h6" component="div" fontWeight="bold" color="primary">
                Manual TradeEngines
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Manually triggered engines.
              </Typography>
            </CardContent>
          </StyledCardActionArea>
        </StyledCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <StyledCard>
          <StyledCardActionArea onClick={() => handleTileClick('/dashboard/myenginesoptionsfree')}>
            <CardContent>
              <Typography variant="h6" component="div" fontWeight="bold" color="success">
                Free Plan TradeEngines
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Engines associated with the Free Plan.
              </Typography>
            </CardContent>
          </StyledCardActionArea>
        </StyledCard>
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <StyledCard>
          <StyledCardActionArea onClick={() => handleTileClick('/dashboard/myenginesoptionsbasic')}>
            <CardContent>
              <Typography variant="h6" component="div" fontWeight="bold" color="info">
                Basic Plan TradeEngines
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Engines associated with the Basic Plan.
              </Typography>
            </CardContent>
          </StyledCardActionArea>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default MyTradeEngines;