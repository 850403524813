// component
import SvgColor from '../../../components/svg-color';
import StyleIcon from '@mui/icons-material/Style';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const navConfig = [
  {
    title: 'Subscription',
    path: '/dashboard/subscription',
    icon: <StyleIcon />, 
  },
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic-dashboard-fill'),
  },
  {
    title: 'Exchanges/Brokers',
    path: '/dashboard/exchanges',
    icon: icon('icon-park-exchange'),
  },
  {
    title: 'Controls',
    path: '/dashboard/mystrategies',
    icon: icon('simple-icons-microstrategy'),
  },
  // {
  //   title: 'Strategies',
  //   path: '/dashboard/myalgos',
  //   icon: icon('token-algo'),
  // },
  {
    title: 'Trade Engines',
    path: '/dashboard/mytradeengines',
    icon: icon('flat-color-icons-eng'),
  },
  // {
  //   title: 'Trade Engines Manual',
  //   path: '/dashboard/myenginesmanual',
  //   icon: icon('flat-color-icons-eng'),
  // },
  // ,
  // {
  //   title: 'Trade Engines Options Free',
  //   path: '/dashboard/myenginesoptionsfree',
  //   icon: icon('flat-color-icons-eng'),
  // },
  // ,
  // {
  //   title: 'Trade Engines Options Basic',
  //   path: '/dashboard/myenginesoptionsbasic',
  //   icon: icon('flat-color-icons-eng'),
  // }
];

export default navConfig;
