import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc,
  getDoc,
  getFirestore
} from "firebase/firestore";
import { getDatabase, ref, set, onValue } from "firebase/database";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { useNavigate } from 'react-router-dom';

import { auth, db, rdb, storage } from "../firebase";
import { useAuth } from "../context/AuthContext";

import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  Grid, Container, Typography, List, ListItem, ListItemText, Button, Collapse, Paper,
  Box
} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  TotalOrderLineChartCard
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const images = [
    '/assets/banner3.svg',
    '/assets/banner4.svg'
  ];

  const navigate = useNavigate();
  const theme = useTheme();
  const [orders, setOrders] = useState([]);

  const [orders2, setOrders2] = useState([]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarTextInfo, setSnackBarTextInfo] = useState("");

  const [trade, setTrade] = useState({});
  const [trade2, setTrade2] = useState({});
  const [exchange, setExchange] = useState({});
  const [strategy, setStrategy] = useState({});
  const [algo, setAlgo] = useState({});
  const [tradeEngine, setTradeEngine] = useState({});

  const [loading, setLoading] = useState(false);
  const { signup, currentUser } = useAuth();
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedOrderLogs, setSelectedOrderLogs] = useState([]);

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleGoToPage = (event, path, activeComponent) => {
    if (path) {
      navigate(path);
    } else {
      setSnackBarTextInfo(activeComponent + "- no open orders")
      setOpenSnackBar(true)
      // alert("Selected Tile is not enabled. Please select other tiles")
    }
  };

  const handleShowLogs = (orderId) => {
    const logs = orders.find((order) => order.id === orderId);
    setSelectedOrderId(orderId);
    setSelectedOrderLogs(logs ? [logs] : []);
  };

  const handleEditOrder = (orderId) => {
    alert("Edit not enabled")
  };

  useEffect(() => {
    // Fetch data from the 'orders' collection
    const fetchData = async () => {
      const docRef = doc(db, 'Customers', currentUser.uid);
      const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   const aStrategies = docSnap.data().strategies.map(obj => obj.id);
      //   const ordersCollection = collection(db, 'ordersNifty50Options');
      //   const squery = query(ordersCollection, where('strategyId', 'in', aStrategies));
      //   const ordersSnapshot = await getDocs(squery);
      //   const ordersData = [];
      //   let orderProcessed = 0;
      //   ordersSnapshot.forEach((doc) => {
      //     orderProcessed++;
      //     // Assuming each order document has a 'description' field
      //     // const { description } = doc.data();
      //     const orderData = doc.data();
      //     const details = `${orderData.exchanges[0].orderno} - ${orderData.exchanges[0].orderstatus} - Master`;
      //     const orderExchange = `${orderData.exchanges[0].exchangeName}`;
      //     const orderColor = `${orderData.exchanges[0].orderstatus === "rejected" ? "error" : "info"}`
      //     const pl = `${orderData.pl}`;
      //     ordersData.push({id:orderProcessed, details: details, type: orderExchange, 
      //       color: orderColor, data : orderData,
      //       pl:pl
      //     });
      //   });
      //   setOrders(ordersData);
      //   let oTradeData = {};
      //   oTradeData.type = "trade"
      //   oTradeData.additionalText = " Trades"
      //   oTradeData.total = ordersData.length
      //   oTradeData.path = "/dashboard/mytrades"
      //   setTrade(oTradeData)

      //   let oExchangeData = {};
      //   oExchangeData.type = "exchange"
      //   oExchangeData.additionalText = " Exchanges"
      //   oExchangeData.total = docSnap.data().exchanges ? docSnap.data().exchanges.length : 0
      //   oExchangeData.path = "/dashboard/exchanges"
      //   setExchange(oExchangeData)

      //   let oStrategyData = {};
      //   oStrategyData.type = "strategy"
      //   oStrategyData.additionalText = " Strategies"
      //   oStrategyData.total = docSnap.data().strategies ? docSnap.data().strategies.length : 0
      //   oStrategyData.path = "/dashboard/mystrategies"
      //   setStrategy(oStrategyData)
      // }
      if (docSnap.exists()) {

        if (docSnap.data() && (docSnap.data().tradeEngines?.length > 0 || docSnap.data().tradeEngines_FREENIFTY50OPTIONSBUYALPHA?.length > 0 ||
          docSnap.data().tradeEngines_BASICNIFTY50OPTIONSBUYALPHA?.length > 0)) {
            
          const aStrategies1 = docSnap.data().tradeEngines?.map(obj => obj.id) || [];
          const aStrategies2 = docSnap.data().tradeEngines_FREENIFTY50OPTIONSBUYALPHA?.map(obj => obj.id) || [];
          const aStrategies3 = docSnap.data().tradeEngines_BASICNIFTY50OPTIONSBUYALPHA?.map(obj => obj.id) || [];
          let aStrategies = [...aStrategies1, ...aStrategies2, ...aStrategies3];
          // const ordersCollection = collection(db, 'ordersNifty50Options');
          // const squery = query(ordersCollection, where('strategyId', 'in', aStrategies));
          // const ordersSnapshot = await getDocs(squery);
          const ordersCollection = ref(rdb, 'ordersNifty50Options');

          const ordersCryptoFuturesCollection = ref(rdb, 'ordersCryptoFutures');

          onValue(ordersCollection, (snapshot) => {
            const _orders = snapshot.val();
            if (_orders) {
              const filteredOrders = Object.values(_orders).filter(order =>
                aStrategies.includes(order.strategyId)
              );
              const ordersData = [];
              let orderProcessed = 0;
              for (const order of filteredOrders) {
                // const orderDetails = orders[orderId];
                orderProcessed++;
                // Assuming each order document has a 'description' field
                // const { description } = doc.data();
                const orderData = order;
                const details = `${orderData.exchanges[0].orderno} - ${orderData.exchanges[0].orderstatus} - Master`;
                const orderExchange = `${orderData.exchanges[0].exchangeName}`;
                const orderColor = `${orderData.exchanges[0].orderstatus === "rejected" ? "error" : "info"}`
                const pl = `${orderData.pl}`;
                ordersData.push({
                  id: orderProcessed, details: details, type: orderExchange,
                  color: orderColor, data: orderData,
                  pl: pl,
                  doc_id: doc.id
                });
              }
              setOrders(ordersData);
              let oTradeData = {};
              oTradeData.type = "trade"
              oTradeData.additionalText = " Trades"
              oTradeData.total = ordersData.length
              oTradeData.path = "/dashboard/mytrades"
              setTrade(oTradeData)
            }
          });

          onValue(ordersCryptoFuturesCollection, (snapshot) => {
            const _orders = snapshot.val();
            if (_orders) {
              const filteredOrders = Object.values(_orders).filter(order =>
                aStrategies.includes(order.strategyId)
              );
              const ordersData = [];
              let orderProcessed = 0;
              for (const order of filteredOrders) {
                // const orderDetails = orders[orderId];
                orderProcessed++;
                // Assuming each order document has a 'description' field
                // const { description } = doc.data();
                const orderData = order;
                const details = `${orderData.exchanges[0].orderno} - ${orderData.exchanges[0].orderstatus} - Master`;
                const orderExchange = `${orderData.exchanges[0].exchangeName}`;
                const orderColor = `${orderData.exchanges[0].orderstatus === "rejected" ? "error" : "info"}`
                const pl = `${orderData.pl}`;
                ordersData.push({
                  id: orderProcessed, details: details, type: orderExchange,
                  color: orderColor, data: orderData,
                  pl: pl,
                  doc_id: doc.id
                });
              }
              setOrders2(ordersData);
              let oTradeData = {};
              oTradeData.type = "trade"
              oTradeData.additionalText = " Trades"
              oTradeData.total = ordersData.length
              oTradeData.path = "/dashboard/mytradescryptofutures"
              setTrade2(oTradeData)
            }
          });
        }

        // let oTradeData = {};
        // oTradeData.type = "trade"
        // oTradeData.additionalText = " Trades"
        // oTradeData.total = orders ? orders.length : 0
        // oTradeData.path = "/dashboard/mytrades"
        // setTrade(oTradeData)

        let oExchangeData = {};
        oExchangeData.type = "exchange"
        oExchangeData.additionalText = " Exchanges"
        oExchangeData.total = docSnap.data().exchanges ? docSnap.data().exchanges.length : 0
        oExchangeData.path = "/dashboard/exchanges"
        setExchange(oExchangeData)

        let oStrategyData = {};
        oStrategyData.type = "strategy"
        oStrategyData.additionalText = " Strategies"
        oStrategyData.total = docSnap.data().strategies ? docSnap.data().strategies.length : 0
        oStrategyData.path = "/dashboard/mystrategies"
        setStrategy(oStrategyData)

        let oAlgoData = {};
        oAlgoData.type = "algo"
        oAlgoData.additionalText = " Algos"
        oAlgoData.total = docSnap.data().algos ? docSnap.data().algos.length : 0
        oAlgoData.path = "/dashboard/myalgos"
        setAlgo(oAlgoData)

        let oTEData = {};
        oTEData.type = "tradeEngine"
        oTEData.additionalText = " Trade Engine"
        oTEData.total = docSnap.data().tradeEngines ? docSnap.data().tradeEngines.length : 0
        oTEData.path = "/dashboard/mytradeEngines"
        setTradeEngine(oTEData)

        // ordersSnapshot.forEach((doc) => {
        //   orderProcessed++;
        //   // Assuming each order document has a 'description' field
        //   // const { description } = doc.data();
        //   const orderData = doc.data();
        //   const details = `${orderData.exchanges[0].orderno} - ${orderData.exchanges[0].orderstatus} - Master`;
        //   const orderExchange = `${orderData.exchanges[0].exchangeName}`;
        //   const orderColor = `${orderData.exchanges[0].orderstatus === "rejected" ? "error" : "info"}`
        //   const pl = `${orderData.pl}`;
        //   ordersData.push({
        //     id: orderProcessed, details: details, type: orderExchange,
        //     color: orderColor, data: orderData,
        //     pl: pl,
        //     doc_id: doc.id
        //   });
        // });
        // setOrders(ordersData);
        // let oTradeData = {};
        // oTradeData.type = "trade"
        // oTradeData.additionalText = " Trades"
        // oTradeData.total = ordersData.length
        // oTradeData.path = "/dashboard/addexchange"
        // setTrade(oTradeData)

        // let oExchangeData = {};
        // oExchangeData.type = "exchange"
        // oExchangeData.additionalText = " Exchanges"
        // oExchangeData.total = docSnap.data().exchanges ? docSnap.data().exchanges.length : 0
        // oExchangeData.path = "/dashboard/exchanges"
        // setExchange(oExchangeData)

        // let oStrategyData = {};
        // oStrategyData.type = "strategy"
        // oStrategyData.additionalText = " Strategies"
        // oStrategyData.total = docSnap.data().strategies ? docSnap.data().strategies.length : 0
        // oStrategyData.path = "/dashboard/mystrategies"
        // setStrategy(oStrategyData)
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | customrapid </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back 👋
        </Typography> */}

        <Box
          sx={{
            height: '50px',
            backgroundColor: 'rgb(30, 136, 229)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start', // Aligns the text to the left
            paddingLeft: '16px', // Adds some padding to the left for spacing
            borderRadius: '8px', // Optional: curved edges
            color: 'white', // Text color
            mb: 5
          }}
        >
          <Typography variant="h6">
            Hi, Welcome back
          </Typography>
        </Box>

        <Typography variant="h6" sx={{ mb: 5, mt: 5 }}>
          Your Dashboard
        </Typography>
        <Grid container spacing={3}>
          {/* for mobile */}
          <Grid item xs={12} sm={12}> {/* Take full width on extra-small and small screens */}
            <Box
              sx={{
                height: { xs: '200px', sm: '300px', md: '400px' }, // Adjust heights for different screen sizes
                backgroundColor: 'white',
                display: { xs: 'flex', sm: 'flex' },  // Always show the mobile slider
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                overflow: 'hidden', // Ensures the content doesn't overflow the banner
                // maxWidth: '100vw', // Set a maximum width
                margin: 'auto',

              }}
            >
              <Slider {...settings} style={{ width: '100%', height: '100%' }}>
                {images.map((src, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={src}
                    alt={`Slide ${index + 1}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',  // Ensure the image covers the entire container without distortion
                    }}
                  />
                ))}
              </Slider>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" sx={{ mb: 2, mt: 5 }}>
              My Orderbook
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              component="div"
              onClick={(e) => handleGoToPage(e, trade.path, "NIFTY50")}
            >
              <AppWidgetSummary
                title="Options - NIFTY50 Order Book"
                total={trade.total ? trade.total : 0}
                path={trade.path}
                additionalText={trade.additionalText}
                icon={'ph:option-bold'} />
            </Box>
          </Grid>


          {/* <Grid item xs={12} sm={6} md={3}>
            <Box
              component="div"
              onClick={(e) => handleGoToPage(e, trade2.path, "CRYPTO FUTURES")}
            >
              <AppWidgetSummary
                title="Crypto - Futures Order Book"
                total={trade2.total}
                path={trade2.path}
                additionalText={trade2.additionalText}
                icon={'ph:option-bold'} />
            </Box>
          </Grid> */}

          {/* <Grid item xs={12} sm={6} md={3}>
            <Box
              component="div"
              onClick={(e) => handleGoToPage(e, strategy.path)}
            >
              <AppWidgetSummary
                title="My Strategies"
                total={strategy.total}
                path={strategy.path}
                additionalText={strategy.additionalText}
                icon={'ant-design:bulb-filled'} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              component="div"
              onClick={(e) => handleGoToPage(e, algo.path)}
            >
              <AppWidgetSummary
                title="My Algos"
                total={algo.total}
                path={algo.path}
                additionalText={algo.additionalText}
                icon={'ant-design:bulb-filled'} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              component="div"
              onClick={(e) => handleGoToPage(e, algo.path)}
            >
              <AppWidgetSummary
                title="My Trade Engines"
                total={tradeEngine.total}
                path={tradeEngine.path}
                additionalText={tradeEngine.additionalText}
                icon={'ant-design:bulb-filled'} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              component="div"
              onClick={(e) => handleGoToPage(e, exchange.path)}
            >
              <AppWidgetSummary
                title="My Exchanges"
                total={exchange.total}
                path={exchange.path}
                additionalText={exchange.additionalText}
                color="info"
                icon={'ant-design:bank-filled'} />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Box
              component="div"
              onClick={(e) => handleGoToPage(e)}
            >
              <AppWidgetSummary
                title="Trade News"
                total={0}
                color="info"
                icon={'ant-design:notification-filled'} />
            </Box>
          </Grid> */}

        </Grid>

        {/* <Grid container spacing={3}>
        {orders.map((order) => (
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="P&L"
              // total={1723}
              total={order.pl}
              icon={'ant-design:sliders-filled'}
              details={order.details}
              type={order.type}
              color={order.color}
              order={order.data}
            />
          </Grid>
           ))}
        </Grid> */}
      </Container>

      <Snackbar open={openSnackBar} autoHideDuration={4000} onClose={handleCloseSnackBar}>
        <Alert
          onClose={handleCloseSnackBar}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackBarTextInfo}
        </Alert>
      </Snackbar>
    </>
  );
}
