import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { filter } from 'lodash';
import {
    collection,
    doc,
    getDocs,
    getDoc,
    updateDoc,
    deleteDoc,
    query,
    where,
    documentId
} from "firebase/firestore";

import { update, child, push } from "firebase/database";
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Box,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
    Collapse,
    Grid,
    TextField,
    Select,
    Divider,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
import { db, rdb } from "../firebase";
import { useAuth } from "../context/AuthContext";
import { Helmet } from 'react-helmet-async';
import DeleteIcon from '@mui/icons-material/Delete';

const strikePriceCe = [
    { value: 4, label: "OTM +4" }, { value: 3, label: "OTM +3" }, { value: 2, label: "OTM +2" },
    { value: 1, label: "OTM +1" }, { value: 0, label: "ATM 0" }, { value: -1, label: "ITM -1" },
    { value: -2, label: "ITM -2" }, { value: -3, label: "ITM -3" }, { value: -4, label: "ITM -4" }
];

const strikePricePe = [
    { value: 4, label: "ITM -4" }, { value: 3, label: "ITM -3" }, { value: 2, label: "ITM -2" },
    { value: 1, label: "ITM -1" }, { value: 0, label: "ATM 0" }, { value: 1, label: "OTM +1" },
    { value: 2, label: "OTM +2" }, { value: 3, label: "OTM +3" }, { value: 4, label: "OTM +4" }
];

const lotSizes = [
    { value: 1, label: "1" }, { value: 2, label: "2" }, { value: 3, label: "3" }, { value: 4, label: "4" }
];

const checkTypes = [
    { value: 'PREMIUM', label: 'Premium' }, { value: 'NIFTY', label: 'NIFTY' }
];

const exitOptions = [
    { value: 'Point', label: 'Point' }, { value: 'Price', label: 'Price' }
];

const CRYPTO_COINS = [
    { value: 'BTCUSDT', label: 'Bitcoin (BTC)' },
    { value: 'ETHUSDT', label: 'Ethereum (ETH)' },
    { value: 'SOLUSDT', label: 'Solana (SOL)' },
    { value: 'AAVEUSDT', label: 'Aave (AAVE)' },
    { value: 'LTCUSDT', label: 'Litecoin (LTC)' },    
];

const TABLE_HEAD = [
    { id: 'name', label: 'Control Plan', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'strike', label: 'Strike Price', alignRight: false },
    { id: 'sl', label: 'SL', alignRight: false },
    { id: 't1', label: 'TKP1', alignRight: false },
    { id: 't2', label: 'TKP2', alignRight: false },
    { id: 'trail', label: 'TL', alignRight: false },
    { id: '' },
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

let STATLIST;

export default function MyStrategiesPage() {
    const [statId, setStatId] = useState(null);
    const [objectPage, setObjectPage] = useState(false)
    const [selectedStat, setSelectedStat] = useState(null)
    const [edit, setEdit] = useState(false)
    const [algos, setAlgos] = useState(null)
    const [uAlgos, setUAlgos] = useState(null)
    const [editCoins, setEditCoins] = useState(false);
    const [tempCoins, setTempCoins] = useState([]);
    const [ltp, setLtp] = useState(null);
    const [lot, setLot] = useState(null);
    const [exitCheckType, setExitCheckType] = useState(null);
    const [exitOption, setExitOption] = useState(null);
    const [tkp1, setTkp1] = useState(null);
    const [tkp2, setTkp2] = useState(null);
    const [sl, setSl] = useState(null);
    const [tl, setTl] = useState(null);

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('name');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [exchangeList, setExchangeList] = useState([]);
    const [loading, setLoading] = useState(false);

    const { signup, currentUser } = useAuth();

    const getList = async () => {
        const docRef = doc(db, 'Customers', currentUser.uid);
        const docSnap = await getDoc(docRef);
        const list = []
        if (docSnap.exists()) {
            const aStrategies = docSnap.data().positionControls;
            const aStrategiesIds = aStrategies.map((strategy) => strategy.id)
            setUAlgos(aStrategies)
            const q = query(collection(db, 'PositionControls'), where(documentId(), 'in', aStrategiesIds));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                const strategy = doc.data()
                const oItem = {
                    avatarUrl: strategy.controlIcon,
                    name: strategy.controlName,
                    type: strategy.optionType,
                    strike: strategy.ltp,
                    sl: strategy.sl,
                    t1: strategy.tkp1,
                    t2: strategy.tkp2,
                    trail: strategy.tl,
                    id: doc.id,
                }
                list.push(oItem);
            });
        }
        setAlgos(list)
        setExchangeList(list)
    };

    useEffect(() => {
        const oDoc = getList();
    }, []);

    console.log(exchangeList);
    STATLIST = exchangeList;

    const handleShowMoreDetails = async (event, id) => {
        setObjectPage(true)
        if (id) {
            setStatId(id)
            const docRef = doc(db, 'PositionControls', id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const oStrategy = docSnap.data()
                setSelectedStat(oStrategy)
                if (oStrategy.tradeCategory !== "CryptoFutures") {
                    setLtp(oStrategy.ltp)
                    setLot(oStrategy.lot)
                    setTkp1(oStrategy.tkp1)
                    setTkp2(oStrategy.tkp2)
                    setSl(oStrategy.sl)
                    setTl(oStrategy.tl)
                    setExitCheckType(oStrategy.exitCheckType)
                    setExitOption(oStrategy.isExitBasedPoint ? "Point" : "Price")
                } else {
                    setTempCoins([...oStrategy.coins]);
                    setEditCoins(false);
                }
            }

        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        alert("Select all disabled. Multi delete not possible. Please select one item at a time")
        return
        if (event.target.checked) {
            const newSelecteds = STATLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };

    const handleEditCoinsToggle = () => {
        setEditCoins(!editCoins);
        if (!editCoins) {
            setTempCoins([...selectedStat.coins]);
        }
    };

    const handleAddCoin = () => {
        setTempCoins([...tempCoins, { coin: '', amount: '' }]);
    };

    const handleCoinChange = (index, field, value) => {
        const newCoins = [...tempCoins];
        newCoins[index][field] = value;
        setTempCoins(newCoins);
    };

    const handleDeleteCoin = (index) => {
        const newCoins = [...tempCoins];
        newCoins.splice(index, 1);
        setTempCoins(newCoins);
    };

    const handleSaveCoins = async () => {
        try {
            setLoading(true);
            const docRef = doc(db, 'PositionControls', statId);
            await updateDoc(docRef, { coins: tempCoins });
            setSelectedStat({ ...selectedStat, coins: tempCoins });
            setAlgos(algos.map(item => item.id === statId ? { ...item, coins: tempCoins } : item))
            setEditCoins(false);
            alert("Coins updated successfully!");
        } catch (error) {
            console.error("Error updating coins:", error);
            alert("Failed to update coins.");
        } finally {
            setLoading(false);
        }
    };

    const handleCancelCoinsEdit = () => {
        setEditCoins(false);
        setTempCoins([...selectedStat.coins]); // Revert to original coins
    };

    const handleEdit = async (event) => {
        setEdit(true)
    };

    const handleCancel = (event) => {
        setLtp(selectedStat.ltp)
        setLot(selectedStat.lot)
        setTkp1(selectedStat.tkp1)
        setTkp2(selectedStat.tkp2)
        setSl(selectedStat.sl)
        setTl(selectedStat.tl)
        setEdit(false)
    };

    const handleSave = async (event) => {
        try {
            const DocRef = doc(db, 'PositionControls', statId);
            await updateDoc(DocRef, {
                "ltp": parseInt(ltp),
                "lot": parseInt(lot),
                "tkp1": parseInt(tkp1),
                "tkp2": parseInt(tkp2),
                "sl": parseInt(sl),
                "tl": parseInt(tl),
                "exitCheckType": exitCheckType,
                "isExitBasedPoint": exitOption === "Point" ? true : false,
                "isExitBasedPrice": exitOption === "Price" ? true : false
            });
            console.log('Strategy updated successfully!');
            selectedStat.ltp = parseInt(ltp)
            selectedStat.lot = parseInt(lot)
            selectedStat.tkp1 = parseInt(tkp1)
            selectedStat.tkp2 = parseInt(tkp2)
            selectedStat.sl = parseInt(sl)
            selectedStat.tl = parseInt(tl)
            selectedStat.exitCheckType = exitCheckType
            selectedStat.isExitBasedPoint = exitOption === "Point" ? true : false,
                selectedStat.isExitBasedPrice = exitOption === "Price" ? true : false
            setEdit(false)
            alert("Strategy updated")
        } catch (error) {
            console.error('Error updating strategy: ', error);
            alert
        }

    };

    const handleManualTriggerList = async (aId) => {
        if (aId) {
            try {
                const newPostKey = push(child(ref(rdb), 'triggers')).key;
                const updates = {};
                updates['/triggers/' + newPostKey] = { sId: aId }
                update(ref(rdb), updates).then(() => {
                    alert("Manual trigger successfully!.Please wait for 5 secs and refresh.")
                })
                    .catch((error) => {
                        alert("Error in trigger. Contact support")
                    });
            } catch (error) {
                console.error('Error', error);
            }
        }
    };

    const handleBackToList = () => {
        setObjectPage(false)
    };

    const handleDeleteAlgo = async () => {
        console.log(selected)
        try {
            for (let i = 0; i < selected.length; i++) {
                let res = await deleteDoc(doc(db, "PositionControls", selected[i]));
                console.log("deleted algo :", res);
            }
            const fAlgos = algos.filter(item => !selected.includes(item.id));
            const fUAlgos = uAlgos.filter(item => !selected.includes(item.id));
            const docRef = doc(db, 'Customers', currentUser.uid);
            let updRes = await updateDoc(docRef, {
                "positionControls": fUAlgos
            })
            console.log(updRes)
            let list = []
            setExchangeList(fAlgos)
            setAlgos(fAlgos)
            setUAlgos(fUAlgos)
            setSelected([])
        } catch (error) {
            alert("Error contact support")
            console.log(error)
        }
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - STATLIST.length) : 0;

    const filteredUsers = applySortFilter(STATLIST, getComparator(order, orderBy), filterName);

    const isNotFound = !filteredUsers.length && !!filterName;
    return (
        <>
            <div style={{ textAlign: 'center' }}>
                {loading && <CircularProgress size={80} style={{ color: 'grey' }} />}
            </div>
            <Helmet>
                <title> Exchanges | customrapid </title>
            </Helmet>
            <Container>
                <Box
                    sx={{
                        height: '50px',
                        backgroundColor: 'rgb(30, 136, 229)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        paddingLeft: '16px',
                        borderRadius: '8px',
                        color: 'white',
                        mb: 5
                    }}
                >
                    <Typography variant="h6">
                        My Controls
                    </Typography>
                </Box>
                {!objectPage &&
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={5}>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" component={Link} to="/dashboard/addstrategy" startIcon={<Iconify icon="eva:plus-fill" />}>
                                Add
                            </Button>
                        </Stack>
                    </Stack>
                }
                {!objectPage &&
                    <Card>
                        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} onDeleteAlgo={handleDeleteAlgo} />
                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 800 }}>
                                <Table>
                                    <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={STATLIST.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={() => { }}
                                    />
                                    <TableBody>
                                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            const { id, name, type, strike, sl, t1, t2, trail, avatarUrl } = row;
                                            const selectedUser = selected.indexOf(id) !== -1;
                                            return (
                                                <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                                                    </TableCell>

                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            <Avatar alt={name} src={avatarUrl} />
                                                            <Typography variant="subtitle2" noWrap>
                                                                {name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="left">{type}</TableCell>
                                                    <TableCell align="left">{strike}</TableCell>
                                                    <TableCell align="left">{sl}</TableCell>
                                                    <TableCell align="left">{t1}</TableCell>
                                                    <TableCell align="left">{t2}</TableCell>
                                                    <TableCell align="left">{trail}</TableCell>
                                                    <TableCell align="right">
                                                        <IconButton size="large" color="inherit" onClick={(event) => handleShowMoreDetails(event, id)}>
                                                            <Iconify icon={'uiw:d-arrow-right'} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for  
                                                            <strong>"{filterName}"</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={STATLIST.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                }

                {objectPage && selectedStat && <Card>

                    <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button
                            variant="contained"
                            onClick={() => handleBackToList()}
                            startIcon={<Iconify icon="eva:arrow-left-fill" />}
                            disabled={loading}
                        >
                            Back
                        </Button>
                    </Box>
                    <Collapse in={selectedStat !== null} timeout="auto" unmountOnExit>
                        <Paper variant="outlined" square style={{ padding: '10px', margin: '10px' }}>
                            <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={2} mr={2}>
                                {selectedStat.tradeCategory === "CryptoFutures" ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            onClick={handleEditCoinsToggle}
                                            color="primary"
                                            disabled={loading}
                                            startIcon={<Iconify icon={editCoins ? "mdi:cancel-bold" : "eva:edit-fill"} />}
                                        >
                                            {editCoins ? "Cancel Edit" : "Edit Coins"}
                                        </Button>
                                        {/* {editCoins && (
                                             <Button variant="contained" onClick={handleCancelCoinsEdit} color="primary" aria-label="cancel" disabled={loading} startIcon={<Iconify icon="mdi:cancel-bold" />}>
                                             Cancel
                                          </Button>
                                        )} */}
                                    </>
                                ) : (
                                    <>
                                        <Button variant="contained" onClick={() => handleEdit()} color="primary" aria-label="edit" startIcon={<Iconify icon="eva:edit-fill" />}
                                            disabled={loading || edit}
                                        >
                                            Edit
                                        </Button>
                                        <Box sx={{ ml: 1 }}>
                                            <Button variant="contained" onClick={() => handleCancel()} color="primary" aria-label="edit" startIcon={<Iconify icon="mdi:cancel-bold" />}
                                                disabled={loading || !edit}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            </Stack>
                            <Typography variant="h6" component="div" sx={{ mt: 2 }} >
                                {selectedStat.controlName}
                            </Typography>
                            <Divider sx={{ mb: 2 }} />

                            {/* General Info */}
                            <Grid container spacing={2} alignItems="center" justifyContent="left" sx={{ mb: 2 }}>
                                <Grid item xs={12} sx={{ mb: 2 }}>
                                    <Typography variant="body2" color="Highlight" component="p" marginTop={2} >
                                        General Info :
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={3} md={3} sx={{ ml: 2 }}>
                                    <Avatar
                                        alt="control icon"
                                        src={selectedStat.controlIcon}
                                        sx={{ width: '40%', height: '40%' }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Typography variant="body2" color="text.secondary">
                                        Trade Category : {selectedStat.tradeCategory}
                                    </Typography>
                                </Grid>
                            </Grid>

                             {/* Conditional Rendering for CryptoFutures vs. Other Categories */}
                             {selectedStat.tradeCategory === "CryptoFutures" ? (
                                <>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ mb: 2 }}>
                                            <Typography variant="body2" color="Highlight" component="p" marginTop={3} >
                                                Coins:
                                            </Typography>
                                        </Grid>
                                        {tempCoins.map((coin, index) => (
                                            <Grid container spacing={2} key={index} alignItems="center">
                                                <Grid item xs={5}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        label={`Coin ${index + 1}`}
                                                        value={coin.coin}
                                                        disabled={!editCoins}
                                                        onChange={(e) => handleCoinChange(index, 'coin', e.target.value)}
                                                    >
                                                        {CRYPTO_COINS.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <TextField
                                                        fullWidth
                                                        label={`Amount ${index + 1}`}
                                                        type="number"
                                                        value={coin.amount}
                                                        disabled={!editCoins}
                                                        onChange={(e) => handleCoinChange(index, 'amount', e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {editCoins && (
                                                        <IconButton onClick={() => handleDeleteCoin(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        ))}
                                        {editCoins && (
                                            <Grid item xs={12}>
                                                <Button onClick={handleAddCoin} variant="contained" color="primary" startIcon={<Iconify icon="mingcute:add-line" />}>
                                                    Add Coin
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            {editCoins && (
                                                <Button variant="contained" onClick={handleSaveCoins} color="primary" aria-label="save" disabled={loading} startIcon={<Iconify icon="material-symbols:save" />}>
                                                    Save Coins
                                                </Button>
                                            )}
                                             {/* {editCoins && (
                                                 <Button variant="contained" onClick={handleCancelCoinsEdit} color="primary" aria-label="cancel" disabled={loading} startIcon={<Iconify icon="mdi:cancel-bold" />}>
                                                     Cancel
                                                 </Button>
                                             )} */}
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {/* Entry Conditions (for non-CryptoFutures) */}
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sx={{ mb: 2 }}>
                                            <Typography variant="body2" color="Highlight" component="p" marginTop={2} >
                                                Entry Conditions :
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body2" color="text.secondary">
                                                Transaction Type : {selectedStat.transaction}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body2" color="text.secondary">
                                                Order Type : {selectedStat.orderType}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Typography variant="body2" color="text.secondary">
                                                Option Type : {selectedStat.optionType}
                                            </Typography>
                                        </Grid>
                                        {!edit &&
                                            <>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Strike Price : {selectedStat.ltp}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Lot Size: {selectedStat.lot}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        {edit &&
                                            <>
                                                {(selectedStat.optionType === "CE") && (
                                                    <>
                                                        <Grid item xs={6} sm={3} md={3}>
                                                            <TextField
                                                                fullWidth
                                                                label="Strike Price (LTP)"
                                                                type="number"
                                                                select
                                                                variant="outlined"
                                                                value={ltp}
                                                                size="small"
                                                                margin="normal"
                                                                onChange={(e) => setLtp(e.target.value)}
                                                            >
                                                                {strikePriceCe.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    </>
                                                )}

                                                {(selectedStat.optionType === "PE") && (
                                                    <>
                                                        <Grid item xs={6} md={3} lg={3} >
                                                            <TextField
                                                                fullWidth
                                                                label="Strike Price (LTP)"
                                                                type="number"
                                                                select
                                                                variant="outlined"
                                                                value={ltp}
                                                                size="small"
                                                                margin="normal"
                                                                onChange={(e) => setLtp(e.target.value)}
                                                            >
                                                                {strikePricePe.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>
                                                    </>
                                                )}
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        label="Lot"
                                                        type="number"
                                                        variant="outlined"
                                                        select
                                                        value={lot}
                                                        size="small"
                                                        margin="normal"
                                                        onChange={(e) => setLot(e.target.value)}
                                                    >
                                                        {lotSizes.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}

                                                    </TextField>
                                                </Grid>
                                            </>
                                        }
                                    </Grid>

                                    {/* Exit Conditions (for non-CryptoFutures) */}
                                    <Grid container spacing={2}>

                                        <Grid item xs={12} sx={{ mb: 1 }}>
                                            <Typography variant="body2" color="Highlight" component="p" marginTop={3} >
                                                Exit Conditions :
                                            </Typography>
                                        </Grid>

                                        {!edit &&
                                            <>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Exit based on : {selectedStat.exitCheckType}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Exit Option : {selectedStat.isExitBasedPoint ? "Point" : "Price"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Take Profit 1: {selectedStat.tkp1}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Take Profit 2: {selectedStat.tkp2}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Stop Loss: {selectedStat.sl}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <Typography variant="body2" color="text.secondary">
                                                        Trailing Loss: {selectedStat.tl}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }

                                        {edit &&
                                            <>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Exit based on"
                                                        type="text"
                                                        select
                                                        variant="outlined"
                                                        value={exitCheckType}
                                                        size="small"
                                                        margin="normal"
                                                        onChange={(e) => setExitCheckType(e.target.value)}
                                                    >
                                                        {checkTypes.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={6} sm={6} md={6}>
                                                    <TextField
                                                        fullWidth
                                                        label="Exit Option"
                                                        type="text"
                                                        select
                                                        variant="outlined"
                                                        value={exitOption}
                                                        size="small"
                                                        margin="normal"
                                                        onChange={(e) => setExitOption(e.target.value)}
                                                    >
                                                        {exitOptions.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        label="Take profit1 (Partial Sell)"
                                                        type="number"
                                                        variant="outlined"
                                                        value={tkp1}
                                                        size="small"
                                                        margin="normal"
                                                        onChange={(e) => setTkp1(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        label="Take Profit 2"
                                                        type="number"
                                                        variant="outlined"
                                                        value={tkp2}
                                                        size="small"
                                                        margin="normal"
                                                        onChange={(e) => setTkp2(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        label="Stop Loss"
                                                        type="number"
                                                        variant="outlined"
                                                        value={sl}
                                                        size="small"
                                                        margin="normal"
                                                        onChange={(e) => setSl(e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={3} md={3}>
                                                    <TextField
                                                        fullWidth
                                                        label="Trailing Loss"
                                                        type="number"
                                                        variant="outlined"
                                                        value={tl}
                                                        size="small"
                                                        margin="normal"
                                                        onChange={(e) => setTl(e.target.value)}
                                                    />
                                                </Grid>
                                            </>}
                                    </Grid>
  <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button variant="contained" onClick={() => handleSave()} color="primary" aria-label="save" startIcon={<Iconify icon="material-symbols:save" />} disabled={loading}>
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Paper>
                    </Collapse>
                </Card>}
            </Container >
        </>
    );
}