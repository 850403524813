import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Grid, TextField, Button, Card, CardContent, Typography, MenuItem,
    FormControlLabel,
    Radio,
    Divider,
    RadioGroup, IconButton
} from '@mui/material';
import Iconify from '../../../components/iconify';
import { Formik, Form, Field, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import {
    addDoc,
    collection,
    doc,
    serverTimestamp,
    getDoc,
    updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { useAuth } from "../../../context/AuthContext";
import DeleteIcon from '@mui/icons-material/Delete';

// Constants for form options
const EXIT_OPTIONS = [
    { value: 'Point', label: 'Point' },
    { value: 'Price', label: 'Price' },
];

const TRADE_CATEGORIES = [
    { value: 'Options', label: 'Options' },
    { value: 'CryptoFutures', label: 'Crypto Futures' },
];

// Crypto Coins option for dropdown
const CRYPTO_COINS = [
    { value: 'BTCUSDT', label: 'Bitcoin (BTC)' },
    { value: 'ETHUSDT', label: 'Ethereum (ETH)' },
    { value: 'SOLUSDT', label: 'Solana (SOL)' },
    { value: 'AAVEUSDT', label: 'Aave (AAVE)' },
    { value: 'LTCUSDT', label: 'Litecoin (LTC)' },
];

const STRIKE_PRICE_CE = [
    { value: 4, label: "OTM +4" },
    { value: 3, label: "OTM +3" },
    { value: 2, label: "OTM +2" },
    { value: 1, label: "OTM +1" },
    { value: 0, label: "ATM 0" },
    { value: -1, label: "ITM -1" },
    { value: -2, label: "ITM -2" },
    { value: -3, label: "ITM -3" },
    { value: -4, label: "ITM -4" }
];

const STRIKE_PRICE_PE = [
    { value: 4, label: "ITM -4" },
    { value: 3, label: "ITM -3" },
    { value: 2, label: "ITM -2" },
    { value: 1, label: "ITM -1" },
    { value: 0, label: "ATM 0" },
    { value: -1, label: "OTM +1" },
    { value: -2, label: "OTM +2" },
    { value: -3, label: "OTM +3" },
    { value: -4, label: "OTM +4" }
];

const LOT_SIZES = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" }
];

const BOOLEAN_TYPES = ['Yes', 'No']; // Consider using boolean values directly

// Exit Check Types
const EXIT_CHECK_TYPES = ['PREMIUM', 'NIFTY'];

// Styles (moved out of component for better organization)
const formStyles = {
    card: { maxWidth: 750, padding: '20px 5px', margin: '0 auto' },
    field: { marginTop: 10 },
};

function CreateStrategyForm() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();

    // Initial form values
    const initialValues = {
        controlName: '',
        tradeCategory: 'Options',
        coins: [{ coin: '', amount: '' }], // Changed quantity to amount
        transaction: 'Buy',
        orderType: 'Market',
        optionType: 'CE',
        exitCheckType: 'PREMIUM',
        lot: 1,  // Default to 1
        ltp: 0,
        tkp1: 0,
        tkp2: 0,
        sl: 0,
        enableTrailingLoss: "No", //defaulting to No
        tl: 0,
        exitOption: 'Point'
    };

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        // controlName: Yup.string().required('Control Name is Required'),
        // lot: Yup.number().when('tradeCategory', {
        //     is: (tradeCategory) => tradeCategory !== 'CryptoFutures',
        //     then: () => Yup.number().required('Lot Size is required').positive("Lot Size must be positive").integer("Lot size must be an integer"),
        //     otherwise: () => Yup.number().notRequired(), // Lot size not required when tradeCategory is CryptoFutures
        // }),
        // tkp1: Yup.number().positive("Take profit 1 must be positive"),
        // tkp2: Yup.number().positive("Take profit 2 must be positive"),
        // sl: Yup.number().positive("Stop loss must be positive"),
        // tl: Yup.number().positive("Trailing loss must be positive"),
        // coins: Yup.array().of(
        //     Yup.object().shape({
        //         coin: Yup.string().required('Crypto Coin is required'),
        //         amount: Yup.number().required('Amount is required').positive("Amount must be positive"), // Changed quantity to amount
        //     })
        // ).when('tradeCategory', {
        //     is: 'CryptoFutures',
        //     then: () => Yup.array().min(1, 'At least one coin is required'),
        //     otherwise: () => Yup.array().notRequired(),
        // }),

    });

    // Form submission handler
    const onSubmit = async (values, { resetForm }) => {
        try {
            const customerDocRef = doc(db, 'Customers', currentUser.uid);
            const customerDoc = await getDoc(customerDocRef);

            if (!customerDoc.exists()) {
                alert('Customer data not found. Please contact support.');
                return;
            }

            const positionControlsCollection = collection(db, 'PositionControls');

            let strategyData = {};
            if (values.tradeCategory === 'CryptoFutures') {
                strategyData = {
                    controlName: values.controlName,
                    controlIcon: "/assets/control_icons/nifty_50.png", //Hardcoded image, needs fixing
                    tradeCategory: values.tradeCategory,
                    coins: values.coins, // Save the array of coins and amounts
                    createdBy: currentUser.uid,
                    createdTimeStamp: serverTimestamp(),
                    lastChangedBy: currentUser.uid, //current user can be the last changed.
                    lastChangedTimeStamp: serverTimestamp()
                };
            } else {
                strategyData = {
                    controlName: values.controlName,
                    controlIcon: "/assets/control_icons/nifty_50.png", //Hardcoded image, needs fixing
                    tradeCategory: values.tradeCategory,
                    transaction: values.transaction,
                    orderType: values.orderType,
                    optionType: values.optionType,
                    exitCheckType: values.exitCheckType,
                    ltp: values.ltp,
                    lot: values.lot,
                    tkp1: values.tkp1,
                    tkp2: values.tkp2,
                    sl: values.sl,
                    enableTrailingLoss: values.enableTrailingLoss,
                    tl: values.tl,
                    tlTriggerpt: 'TakeProfit1', // Hardcoded value
                    isExitBasedPoint: values.exitOption === "Point",
                    isExitBasedPrice: values.exitOption === "Price",
                    createdBy: currentUser.uid,
                    createdTimeStamp: serverTimestamp(),
                    lastChangedBy: currentUser.uid, //current user can be the last changed.
                    lastChangedTimeStamp: serverTimestamp()
                };
            }


            const newStrategyDocRef = await addDoc(positionControlsCollection, strategyData);

            // Update the customer document with the new strategy
            const customerData = customerDoc.data();
            const updatedPositionControls = customerData.positionControls
                ? [...customerData.positionControls, { name: values.controlName, id: newStrategyDocRef.id }]
                : [{ name: values.controlName, id: newStrategyDocRef.id }];

            await updateDoc(customerDocRef, { positionControls: updatedPositionControls });

            console.log("Document written with ID: ", newStrategyDocRef.id);
            alert(`Strategy "${values.controlName}" created successfully!`);
            resetForm();
            navigate('/dashboard/mystrategies');

        } catch (error) {
            console.error("Error creating strategy:", error);
            alert("Failed to create strategy. Please contact support.");
        }
    };

    return (
        <div className="App">
            <Grid>
                <Card style={formStyles.card}>
                    <CardContent>
                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                            {({ values, errors, touched, setFieldValue }) => (
                                <Form noValidate>
                                    <Typography variant="h6" gutterBottom>
                                        General Info
                                    </Typography>
                                    <Divider sx={{ mb: 3 }} />

                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={7}>
                                            <Field
                                                as={TextField}
                                                name="controlName"
                                                label="Control Name"
                                                fullWidth
                                                error={errors.controlName && touched.controlName}
                                                helperText={<ErrorMessage name="controlName" />}
                                                style={formStyles.field}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <Field
                                                as={TextField}
                                                select
                                                label="Trade Category"
                                                name="tradeCategory"
                                                defaultValue="Options"
                                                helperText="Please select your trade category"
                                                fullWidth
                                                style={formStyles.field}
                                            >
                                                {TRADE_CATEGORIES.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </Grid>


                                        {/*Crypto Futures fields*/}
                                        {values.tradeCategory === 'CryptoFutures' && (
                                            <>
                                                <Typography variant="h6" gutterBottom>
                                                    Crypto Details
                                                </Typography>
                                                {values.coins.map((coinObj, index) => (
                                                    <Grid container spacing={2} key={index}>
                                                        <Grid item xs={5}>
                                                            <Field
                                                                as={TextField}
                                                                select
                                                                label={`Coin ${index + 1}`}
                                                                name={`coins[${index}].coin`}
                                                                fullWidth
                                                                error={touched.coins && touched.coins[index] && errors.coins && errors.coins[index] && errors.coins[index].coin}
                                                                helperText={touched.coins && touched.coins[index] && errors.coins && errors.coins[index] && errors.coins[index].coin ? errors.coins[index].coin : null}
                                                            >
                                                                {CRYPTO_COINS.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <Field
                                                                as={TextField}
                                                                label={`Amount ${index + 1}`}
                                                                name={`coins[${index}].amount`}
                                                                type="number"
                                                                fullWidth
                                                                error={touched.coins && touched.coins[index] && errors.coins && errors.coins[index] && errors.coins[index].amount}
                                                                helperText={touched.coins && touched.coins[index] && errors.coins && errors.coins[index] && errors.coins[index].amount ? errors.coins[index].amount : null}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <IconButton
                                                                color="error"
                                                                aria-label="delete"
                                                                onClick={() => {
                                                                    const newCoins = [...values.coins];
                                                                    newCoins.splice(index, 1);
                                                                    setFieldValue('coins', newCoins);
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Grid item xs={12}>
                                                    <Button onClick={() => setFieldValue('coins', [...values.coins, { coin: '', amount: '' }])} variant="contained" color="primary" startIcon={<Iconify icon="mingcute:add-line" />}>
                                                        Add Coin
                                                    </Button>
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>

                                    <Divider sx={{ mt: 3 }} />
                                    {/* Show other conditions when tradeCategory is not CryptoFutures */}
                                    {values.tradeCategory !== 'CryptoFutures' && (
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                Entry & Exit Conditions
                                            </Typography>
                                            <Divider sx={{ mb: 1 }} />

                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2" color="Highlight" component="p" marginTop={2}>
                                                        Entry conditions:
                                                    </Typography>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        Transaction:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Field name="transaction">
                                                        {({ field }) => (
                                                            <RadioGroup row aria-label="transaction type" {...field}>
                                                                <FormControlLabel value="Buy" control={<Radio />} label="Buy" disabled />
                                                                {/*  future enhancement, disable=false, can sell if feature is added */}
                                                            </RadioGroup>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="transaction" component="div" />
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        Order Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Field name="orderType">
                                                        {({ field }) => (
                                                            <RadioGroup row aria-label="order type" {...field}>
                                                                <FormControlLabel value="Market" control={<Radio />} label="Market" />
                                                                {/* future enhancement, disable=false, can select Limit if feature is added */}
                                                            </RadioGroup>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="orderType" component="div" />
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        Option Type:
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <Field name="optionType">
                                                        {({ field }) => (
                                                            <RadioGroup row aria-label="option type" {...field}>
                                                                <FormControlLabel value="CE" control={<Radio />} label="CE" />
                                                                <FormControlLabel value="PE" control={<Radio />} label="PE" />
                                                            </RadioGroup>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="optionType" component="div" />
                                                </Grid>

                                                {values.optionType === "CE" && (
                                                    <Grid item xs={6} md={6}>
                                                        <Field
                                                            as={TextField}
                                                            select
                                                            label="Strike Price"
                                                            name="ltp"
                                                            defaultValue={0}
                                                            fullWidth
                                                            style={formStyles.field}
                                                        >
                                                            {STRIKE_PRICE_CE.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                    </Grid>
                                                )}

                                                {values.optionType === "PE" && (
                                                    <Grid item xs={6} md={6}>
                                                        <Field
                                                            as={TextField}
                                                            select
                                                            label="Strike Price"
                                                            name="ltp"
                                                            defaultValue={0}
                                                            fullWidth
                                                            style={formStyles.field}
                                                        >
                                                            {STRIKE_PRICE_PE.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Field>
                                                    </Grid>
                                                )}

                                                <Grid item xs={6} md={6}>
                                                    <Field
                                                        as={TextField}
                                                        select
                                                        label="Lot Size"
                                                        name="lot"
                                                        defaultValue={1}
                                                        fullWidth
                                                        style={formStyles.field}
                                                    >
                                                        {LOT_SIZES.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </Grid>

                                                {(values.optionType === "CE" || values.optionType === "PE") && (
                                                    <>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body2" color="Highlight" component="p" marginTop={2}>
                                                                Exit conditions:
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item xs={12} md={3}>
                                                            <Typography variant="body2" color="textSecondary" component="p">
                                                                Exit based on:
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={9}>
                                                            <Field name="exitCheckType">
                                                                {({ field }) => (
                                                                    <RadioGroup row aria-label="exit Check Type" {...field}>
                                                                        {EXIT_CHECK_TYPES.map((type) => (
                                                                            <FormControlLabel key={type} value={type} control={<Radio />} label={type} />
                                                                        ))}
                                                                    </RadioGroup>
                                                                )}
                                                            </Field>
                                                            <ErrorMessage name="exitCheckType" component="div" />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Field
                                                                as={TextField}
                                                                select
                                                                label="Exit Option"
                                                                name="exitOption"
                                                                defaultValue="Point"
                                                                helperText="Please see the instruction down below"
                                                                fullWidth
                                                                style={formStyles.field}
                                                            >
                                                                {EXIT_OPTIONS.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </Grid>

                                                        <Grid item xs={6} md={4}>
                                                            <Field
                                                                as={TextField}
                                                                name="tkp1"
                                                                label="Take Profit 1 (Mid Sell)"
                                                                type="number"
                                                                fullWidth
                                                                error={errors.tkp1 && touched.tkp1}
                                                                helperText={<ErrorMessage name="tkp1" />}
                                                                style={formStyles.field}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={6} md={4}>
                                                            <Field
                                                                as={TextField}
                                                                name="tkp2"
                                                                label="Take Profit 2"
                                                                type="number"
                                                                fullWidth
                                                                error={errors.tkp2 && touched.tkp2}
                                                                helperText={<ErrorMessage name="tkp2" />}
                                                                style={formStyles.field}
                                                            />
                                                        </Grid>

                                                        <Grid item xs={6} md={4}>
                                                            <Field
                                                                as={TextField}
                                                                name="sl"
                                                                label="Stop Loss"
                                                                type="number"
                                                                fullWidth
                                                                error={errors.sl && touched.sl}
                                                                helperText={<ErrorMessage name="sl" />}
                                                                style={formStyles.field}
                                                            />
                                                        </Grid>
                                                    </>
                                                )}

                                                <Grid item xs={12} md={6}>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        Enable Trailing Loss (Trigger Point will be Take profit 1)
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Field name="enableTrailingLoss">
                                                        {({ field }) => (
                                                            <RadioGroup row aria-label="enable trailing loss" {...field}>
                                                                {BOOLEAN_TYPES.map((bool) => (
                                                                    <FormControlLabel key={bool} value={bool} control={<Radio />} label={bool} />
                                                                ))}
                                                            </RadioGroup>
                                                        )}
                                                    </Field>
                                                    <ErrorMessage name="enableTrailingLoss" component="div" />
                                                </Grid>

                                                {values.enableTrailingLoss === "Yes" && (
                                                    <Grid item xs={12} md={6}>
                                                        <Field
                                                            as={TextField}
                                                            name="tl"
                                                            label="Trailing Loss Step"
                                                            type="number"
                                                            fullWidth
                                                            error={errors.tl && touched.tl}
                                                            helperText={<ErrorMessage name="tl" />}
                                                            style={formStyles.field}
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button type="submit" variant="contained" color="primary" startIcon={<Iconify icon="material-symbols:save" />}>
                                            Save
                                        </Button>
                                    </Grid>

                                    {values.tradeCategory !== 'CryptoFutures' && (
                                        <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
                                            <Typography variant="h6">Instructions</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                1. Exit Option has two values Price or Point. If Price selected then SL, Take Profit etc should be
                                                mentioned based on NIFTY 50/Premium Price. If Point is selected you can mention SL, Take Profit based on Points which will be added or
                                                substracted from NIFTY 50 Price/Premium. <br />
                                                2. Ensure that your stop-loss and take-profit values are not zero. If zero, the trade will be immediately closed.<br />
                                                3. The trailing loss will be disabled/off if its value is zero.<br />
                                                4. Partial sell/Mid sell will be disabled/off if its value is zero.<br />
                                            </Typography>
                                        </Grid>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
}

export default CreateStrategyForm;