import React, { useState } from "react";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    CircularProgress,
    LinearProgress
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import {
    addDoc,
    collection,
    doc,
    serverTimestamp,
    Timestamp,
    setDoc,
    query,
    where,
    getDocs,
    updateDoc,
    getDoc,
} from 'firebase/firestore';
import { auth, db, storage } from '../firebase';
import { useAuth } from '../context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
const SubscriptionPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { signup, currentUser } = useAuth();
    const [isDisabledFree, setIsDisabledFree] = useState(false);
    const [isDisabledBasic, setIsDisabledBasic] = useState(false);
    const [existingSubscriptions, setExistingSubscriptions] = useState([]);

    const handleTileClick = (path) => {
        navigate(path);
      };

    useEffect(() => {
        getList();
      }, []);

      const getList = async () => {
        try {
            const docRef = doc(db, "Customers", currentUser.uid);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const aSubscription = docSnap.data().subscriptions || [];
                setExistingSubscriptions(aSubscription);
                const hasFreePlan = aSubscription.some(sub => sub.planId === "FREENIFTY50OPTIONSBUYALPHA");
                setIsDisabledFree(hasFreePlan); 
                const hasBasicPlan = aSubscription.some(sub => sub.planId === "BASICNIFTY50OPTIONSBUYALPHA");
                setIsDisabledBasic(hasBasicPlan);
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching subscriptions:", error);
        }
    };
    
    const onSubscribe = async (plan) => {
        try {
            setLoading(true);
            const allowedPlans = ["FREENIFTY50OPTIONSBUYALPHA", "BASICNIFTY50OPTIONSBUYALPHA"];
            if (!allowedPlans.includes(plan)) {
                setLoading(false);
                return;
            }
            const docRef = doc(db, 'Customers', currentUser.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {

                const docRefCoCE = doc(db, 'PositionControls', 'tP9IpTZHKnyC32AuiLos');
                const docSnapCoCE = await getDoc(docRefCoCE);
                const oCoCE = docSnapCoCE.data() || {}
                const docRefCoPE = doc(db, 'PositionControls', '60BHTqnzlNBTdmKYcSam');
                const docSnapCoPE = await getDoc(docRefCoPE);
                const oCoPE = docSnapCoPE.data() || {}

                const docRefStCE = doc(db, 'Strategies', 'EqvDtreqqCiD5bjNHwle');
                const docSnapStCE = await getDoc(docRefStCE);
                const oStCE = docSnapStCE.data() || {}
                const docRefStPE = doc(db, 'Strategies', 'ZN6jeNdTEyrYNrhsuHBN');
                const docSnapStPE = await getDoc(docRefStPE);
                const oStPE = docSnapStPE.data() || {}

                const oLinkedStCE = {
                    "controlCEId": "tP9IpTZHKnyC32AuiLos",
                    "controlCE": oCoCE,
                    "controlPEId": "",
                    "controlPE": "",
                    "strategy": oStCE
                }

                const oLinkedStPE = {
                    "controlCEId": "",
                    "controlCE": "",
                    "controlPEId": "60BHTqnzlNBTdmKYcSam",
                    "controlPE": oCoPE,
                    "strategy": oStPE
                }

                const aMyBrokers = docSnap.data().exchanges ? docSnap.data().exchanges : [];
                const aBrokers = []
                const angeloneBrokers = aMyBrokers.filter((item) => item.exchangeName === "Angelone");
                if (angeloneBrokers.length > 1) {
                    alert('Multiple Angelone Broker entries found.Please delete the extras and keep only one');
                    setLoading(false);
                    return;
                }else if (angeloneBrokers.length === 0) {
                    alert('No Broker/Exchange detected. Please navigate to the side panel under "Exchanges/Broker" and link an Exchange/Broker to proceed!');
                    setLoading(false);
                    return;
                }
                let selectedBroker = aMyBrokers.find((item) => item.exchangeName === "Angelone");
                const oBroker = {
                    customer: currentUser.uid,
                    exchangeName: selectedBroker.exchangeName,
                    exchangeId: selectedBroker.exchangeId
                }
                aBrokers.push(oBroker)

                let oDataTeCE = {
                    plan: plan,
                    planStartDate: serverTimestamp(),
                    planEndDate: Timestamp.fromDate(new Date(Date.now() + 28 * 24 * 60 * 60 * 1000)), // Add 28 days
                    isActive: false,
                    tradeEngineName: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? "Real_Basic_Nifty50_Options_Buy_CE_Alpha" : "Paper_Free_Nifty50_Options_Buy_CE_Alpha",
                    engineIcon: "",
                    engineType: "AT",
                    linkedStrategyId: "EqvDtreqqCiD5bjNHwle",
                    linkedControlId: "tP9IpTZHKnyC32AuiLos",
                    tradeCategory: "Options",
                    transaction: "Buy",
                    optionType: "CE",
                    market: "NIFTY50",
                    enablePaperTrade: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? false : true,
                    MasterCustomer: currentUser.uid,
                    MasterCustomerEmailId: currentUser.email,
                    MasterExchangeName: selectedBroker.exchangeName,
                    MasterExchangeId: selectedBroker.exchangeId,
                    linkedStat: oLinkedStCE ? oLinkedStCE : '',
                    linkedExchanges: aBrokers ? aBrokers : [],
                    createdBy: currentUser.uid,
                    createdTimeStamp: serverTimestamp(),
                    lastChangedBy: currentUser.uid,
                    lastChangedTimeStamp: serverTimestamp()
                }

                const sCollectionName = plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? "TradeEngines_BASICNIFTY50OPTIONSBUYALPHA" : "TradeEngines_FREENIFTY50OPTIONSBUYALPHA"
                let responseTeCE = await addDoc(collection(db, sCollectionName), oDataTeCE);

                let oDataTePE = {
                    plan: plan,
                    planStartDate: serverTimestamp(),
                    planEndDate: Timestamp.fromDate(new Date(Date.now() + 28 * 24 * 60 * 60 * 1000)), // Add 28 days
                    isActive: false,
                    tradeEngineName: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? "Real_Basic_Nifty50_Options_Buy_PE_Alpha" : "Paper_Free_Nifty50_Options_Buy_PE_Alpha",
                    engineIcon: "",
                    engineType: "AT",
                    linkedStrategyId: "ZN6jeNdTEyrYNrhsuHBN",
                    linkedControlId: "60BHTqnzlNBTdmKYcSam",
                    tradeCategory: "Options",
                    transaction: "Buy",
                    optionType: "PE",
                    market: "NIFTY50",
                    enablePaperTrade: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? false : true,
                    MasterCustomer: currentUser.uid,
                    MasterCustomerEmailId: currentUser.email,
                    MasterExchangeName: selectedBroker.exchangeName,
                    MasterExchangeId: selectedBroker.exchangeId,
                    linkedStat: oLinkedStPE ? oLinkedStPE : '',
                    linkedExchanges: aBrokers ? aBrokers : [],
                    createdBy: currentUser.uid,
                    createdTimeStamp: serverTimestamp(),
                    lastChangedBy: currentUser.uid,
                    lastChangedTimeStamp: serverTimestamp()
                }
                let responseTePE = await addDoc(collection(db, sCollectionName), oDataTePE);

                let aTradeEngines = docSnap.data()[sCollectionName] ? docSnap.data()[sCollectionName] : [];
                aTradeEngines.push({
                    name: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? "Real_Basic_Nifty50_Options_Buy_CE_Alpha" : "Paper_Free_Nifty50_Options_Buy_CE_Alpha",
                    id: responseTeCE.id
                })

                aTradeEngines.push({
                    name: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? "Real_Basic_Nifty50_Options_Buy_PE_Alpha" : "Paper_Free_Nifty50_Options_Buy_PE_Alpha",
                    id: responseTePE.id
                })

                let aSubscription = docSnap.data().subscriptions ? docSnap.data().subscriptions : [];
                const dPlanStartDate = Timestamp.now();

                const dPlanEndDate = Timestamp.fromDate(new Date(Date.now() + 28 * 24 * 60 * 60 * 1000));
                aSubscription.push({
                    planId: plan,
                    name: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? "Basic Nifty50 Options Buy Alpha" : "Free Nifty50 Options Buy Alpha",
                    TECE: responseTeCE.id,
                    TEPE: responseTePE.id,
                    collection: sCollectionName,
                    planStartDate: dPlanStartDate,
                    planEndDate: dPlanEndDate, // Add 28 days
                })

                if (plan === "FREENIFTY50OPTIONSBUYALPHA") {
                    await updateDoc(docRef, {
                        tradeEngines_FREENIFTY50OPTIONSBUYALPHA: aTradeEngines,
                        subscriptions: aSubscription
                    });

                } else if (plan === "BASICNIFTY50OPTIONSBUYALPHA") {
                    await updateDoc(docRef, {
                        tradeEngines_BASICNIFTY50OPTIONSBUYALPHA: aTradeEngines,
                        subscriptions: aSubscription
                    });
                }

                // console.log("Document written with ID: ", [sCollectionName, responseTeCE.id, responseTePE.id]);
                alert("Your trade engines are successfully created! Head over to the TradeEngines tab to activate them and start trading");
                if (plan === "FREENIFTY50OPTIONSBUYALPHA") {
                    setIsDisabledFree(true)
                    let aSubs = [{
                        planId: plan,
                        name: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? "Basic Nifty50 Options Buy Alpha" : "Free Nifty50 Options Buy Alpha",
                        TECE: responseTeCE.id,
                        TEPE: responseTePE.id,
                        collection: sCollectionName,
                        planStartDate: dPlanStartDate,
                        planEndDate: dPlanEndDate, // Add 28 days
                    }]
                    aSubs = [...aSubs, ...existingSubscriptions]
                    setExistingSubscriptions(aSubs)

                } else if (plan === "BASICNIFTY50OPTIONSBUYALPHA") {
                    setIsDisabledBasic(true)
                    let aSubs = [{
                        planId: plan,
                        name: plan && plan === "BASICNIFTY50OPTIONSBUYALPHA" ? "Basic Nifty50 Options Buy Alpha" : "Free Nifty50 Options Buy Alpha",
                        TECE: responseTeCE.id,
                        TEPE: responseTePE.id,
                        collection: sCollectionName,
                        planStartDate: dPlanStartDate,
                        planEndDate: dPlanEndDate, // Add 28 days
                    }]
                    aSubs = [...aSubs, ...existingSubscriptions]
                    setExistingSubscriptions(aSubs)                    
                }
                setLoading(false);
            } else {
                // doc.data() will be undefined in this case
                alert('Error - Exchange/Broker linking failed');
                setLoading(false);
                // setSnackBarTextInfo('Error - Exchange/Broker linking failed')
                // setErrorPresent(true)
                // setOpenSnackBar(true)
                // props.resetForm();
            }
        } catch (error) {
            console.log(error);
            alert('Unexpected error. Please contact support');
            setLoading(false);
            // setSnackBarTextInfo('Unexpected error. Please contact support')
            // setErrorPresent(true)
            // setOpenSnackBar(true)
        }
    };

    return (
        <>
            <Helmet>
                <title> Subscription | customrapid </title>
            </Helmet>
            {loading && <LinearProgress />}
            <Container maxWidth="md" sx={{ mt: 4 }}>
                <Box
                    sx={{
                        height: '50px',
                        backgroundColor: 'rgb(30, 136, 229)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start', // Aligns the text to the left
                        paddingLeft: '16px', // Adds some padding to the left for spacing
                        borderRadius: '8px', // Optional: curved edges
                        color: 'white', // Text color
                        mb: 5
                    }}
                >
                    <Typography variant="h6">
                        Subscription
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    {/* Free Plan */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ height: 270 }}>
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    Free Plan
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    NIFTY 50 Options Strategy
                                </Typography>
                                <ul style={{ height: '80px', overflowY: 'auto', paddingLeft: '20px' }}>
                                    <li>
                                        <Typography variant="body2">
                                            Only paper trading allowed
                                        </Typography>
                                    </li>
                                </ul>
                                <div style={{ marginTop: 20 }}>
                                    <Button variant="contained" onClick={() => onSubscribe("FREENIFTY50OPTIONSBUYALPHA")} color="primary" sx={{ mr: 1, width: '120px' }}
                                        disabled={loading || isDisabledFree }>
                                        {/* startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}> */}
                                        Subscribe
                                    </Button>
                                    <div style={{ marginTop: 10 }}>
                                        <Button variant="outlined" color="primary" sx={{ mr: 1, width: '120px' }} onClick={() => handleTileClick('/dashboard/performance')}>
                                            Performance
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Basic Plan */}
                    <Grid item xs={12} md={4}>
                        <Card sx={{ height: 270 }}>
                            <CardContent>
                                <Typography variant="h6" component="div">
                                    Basic Plan
                                </Typography>
                                <Typography variant="subtitle1" color="text.secondary">
                                    NIFTY 50 Options Strategy
                                </Typography>
                                <ul style={{ height: '80px', overflowY: 'auto', paddingLeft: '20px' }}>
                                    <li>
                                        <Typography variant="body2">
                                            Real trading allowed
                                        </Typography>
                                    </li>
                                    {/* <li>
                                        <Typography variant="body2">
                                            Expected monthly return approx ₹10,000
                                        </Typography>
                                    </li> */}
                                </ul>
                                <div style={{ marginTop: 20 }}>
                                    <Button variant="contained" onClick={() => onSubscribe("BASICNIFTY50OPTIONSBUYALPHA")} color="primary" sx={{ mr: 1, width: '120px' }}
                                        disabled={loading || isDisabledBasic}>
                                        {/* startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}> */}
                                        Subscribe
                                    </Button>
                                    <div style={{ marginTop: 10 }}>
                                        <Button variant="outlined" color="primary" sx={{ mr: 1, width: '120px' }} onClick={() => handleTileClick('/dashboard/performance')} >
                                            Performance
                                        </Button>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/* Existing Subscriptions Table */}
                <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
                    Existing Subscriptions
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Plan</TableCell>
                                <TableCell align="left">Start Date</TableCell>
                                <TableCell align="left">Expiry Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {existingSubscriptions.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left">{row.planStartDate.toDate().toLocaleDateString()}</TableCell>
                                    <TableCell align="left">{row.planEndDate.toDate().toLocaleDateString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>

    );
};

export default SubscriptionPage;